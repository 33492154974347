.unseal-warning{
  font-family: 'JKR Haas Grotesk Bold';
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 43px;
  text-align: center;
  color: #0A0909;
  width:100%;
  text-align:center;
}

.unseal-warning-subtext{
  font-family: 'JKR Haas Grotesk';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #8D8D8D;
  margin:24px 0px;
}

.unseal-action-modal-button{
  cursor:pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 40px;
  height: 56px;
  background: #D90E0E;
  border-radius: 40px;
  box-sizing:border-box;
  width:100%;
  font-family: 'JKR Haas Grotesk Bold';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #FAFAFA;
  margin-bottom:24px;
}

.keep-secret{
  font-family: 'JKR Haas Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  text-decoration-line: underline;
  color: #8D8D8D;
  cursor:pointer;
}

.modal-container-white{
  background:white;
}
