.nft-image {
  width:200px;
  height:200px;
}

.nft-container {
  padding:15px;
  border:1px solid gray;
  display:inline-block;
 
}
