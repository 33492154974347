.landing-container{
  width:100%;
  height:100%;
  display:flex;
  align-items:center;
  justify-content:center;
}

.landing-container-black{
  background: #0A0909; 
  color: #FAFAFA;
}

.landing-container-white{
  color: #0A0909;
}


.landing-connect-button-container{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 416px;
  height: 77px;
  border: 2px solid #FAFAFA;
  border-radius: 40px;
}

.landing-container-black .landing-connect-button-container{
  border: 2px solid #FAFAFA;
}

.landing-container-white .landing-connect-button-container{
  border: 2px solid #0A0909;
}

.landing-spinner{
  height:29px;
  width:29px;
}

.landing-container-center-content{
  display:flex;
  align-items:center;
  flex-direction:column;
}

.landing-logo{
  margin-bottom:27px;
  height:25px;
}

.landing-center-header-text{
  font-family: JKR Haas Grotesk;
  font-weight: 400;
  font-size: 36px;
  line-height: 43px;
}

.landing-container-black .landing-center-header-text{
  color: #FAFAFA;
}

.landing-container-white .landing-center-header-text{
  color: #0A0909;
}

.landing-connect-button-container .connect-button-text{
  font-family: 'JKR Haas Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
}

.landing-subtext{
  font-family: 'JKR Haas Grotesk';
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 24px;
}
