.header{
  width:100%;
  box-sizing:border-box;
  height:120px;
  display:flex;
  align-items:center;
  justify-content:space-between;
  padding-left:204px;
  padding-right:204px;
  position:fixed;
  top:0px;
}
  
.header-right {
  display:flex;
  align-items:center;
}

.black-header{
  background: #0A0909;
  color: #FAFAFA;
}

.white-header{
  color: #0A0909;
  background: white;
}

.header-link{
  font-family: 'JKR Haas Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-decoration-line: underline;
  cursor:pointer;
  margin-right:64px;
}

.header-link:hover{
  color:blue !important;
}

.header-wallet-button{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 24px;
  height: 56px;
  border: 2px solid #FAFAFA;
  border-radius: 40px;
  font-family: 'JKR Haas Grotesk';
  box-sizing:border-box;
}

.white-header .header-wallet-button{
  border-color: #0A0909;
}

