.modal-background{
  width:100%;
  height:100%;
  position:fixed;
  top:0px;
  z-index:99;
  background-color: rgba(222, 222, 222, 0.7);
  display:flex;
  align-items:center;
  justify-content:center;
}

.modal-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 56px;
  position: relative;
  width: 632px;
  background: #0A0909;
  border-radius: 40px;
  box-sizing:border-box;
  z-index:100;
}

.modal-top-row{
  display:flex;
  align-items:center;
  justify-content:space-between;
  height:75px;
  box-sizing:border-box;
  width:100%;
}

.modal-top-row-left{
  display:flex;
  align-items:flex-start;
  justify-content:space-between;
  flex-direction:column;
  height:100%;
}

.modal-top-row-left-top-text{
  font-family: 'JKR Haas Grotesk Bold';
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 43px;
  color: #FAFAFA;
}

.modal-top-row-left-bottom-text{
  font-family: 'JKR Haas Grotesk';
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 24px;
  color: #8D8D8D;
}

.modal-top-row-image{
  width:56px;
  height:56px;
}

.modal-nft-container{
  width:100%;
  display:flex;
  align-items:center;
  justify-content:flex-start;
  padding: 24px;
  height: 120px;
  background: #181A1E;
  border: 1px solid #8D8D8D;
  border-radius: 10px;
  box-sizing:border-box;
  margin-top:32px;
  margin-bottom:8px;
}

.modal-container-white .modal-nft-container{
  background: #EEEEEE;
}

.modal-nft-image-box{
  width:72px;
  height:72px;
  display:flex;
  align-items:center;
  justify-content:center;
  display:flex;
  border: 0.18px solid #FFFFFF;
  border-radius: 4.32px;
  box-sizing:border-box;
}

.modal-nft-image-box img{
  width:100%;
  height:100%;
}

.modal-nft-name{
  font-family: 'JKR Haas Grotesk Bold';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;
  color: #9C9DA1;
  margin-left:24px;
}


.modal-container-white .modal-nft-name{
  color: #0A0909;
}

.modal-subtext{
  font-family: 'JKR Haas Grotesk';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #8D8D8D;
}

.view-nft-button{
  cursor:pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 40px;
  height: 62px;
  background: #43BA7D;
  border-radius: 40px;
  width:100%;
  box-sizing:border-box;
  font-family: 'JKR Haas Grotesk Bold';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #FAFAFA;
  margin-top:24px;
}

.sign-reveal-button{
  cursor:pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 40px;
  height: 56px;
  background: #43BA7D;
  border-radius: 40px;
  box-sizing:border-box;
  font-family: 'JKR Haas Grotesk Bold';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #FAFAFA;
  width:100%;
}

.modal-button-row{
  display:flex;
  align-items:center;
  justify-content:space-between;
  margin-top:16px;
  width:100%;
}

.copy-code-modal-button{
  cursor:pointer;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 252px;
  height: 56px;
  border: 2px solid #0A0909;
  border-radius: 40px;
  font-family: 'JKR Haas Grotesk Bold';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #0A0909;
}

.view-nft-modal-button{
  cursor:pointer;
  font-family: 'JKR Haas Grotesk Bold';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #FAFAFA;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 252px;
  height: 56px;
  background: #0A0909;
  border: 2px solid #0A0909;
  border-radius: 40px;
}
