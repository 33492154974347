.create-center{
  display:flex;
  align-items:center;
  justify-content:space-between;
} 

.cube-box{
  box-sizing: border-box;
  width: 400px;
  height: 400px;
  border: 1px solid #8D8D8D;
  border-radius: 24px;
  display:flex;
  align-items:center;
  justify-content:center;
  background:black;
}


.cube-box-img{
  width:275px;
  height:275px;
}

.create-center-right{
  display:flex;
  align-items:flex-start;
  justify-content:center;
  flex-direction:column;
  margin-left:56px;
  width:400px;
  box-sizing:border-box;
}

.create-center-left{
  display:flex;
  align-items:flex-start;
  justify-content:center;
  flex-direction:column;
  margin-right:56px;
  width:400px;
  box-sizing:border-box;
}

.sealed-tag{
  box-sizing:border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 16px;
  height: 40px;
  background: rgba(67, 186, 125, 0.2);
  border-radius: 10px;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 120%;
  color: #43BA7D;
}

.success-nft-title{
  font-family: 'JKR Haas Grotesk Bold';
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 43px;
  color: #FAFAFA;
  margin-top:40px;
  margin-bottom:32px;
}

.sol-explorer-button{
  cursor:pointer;
  text-decoration:none;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 192px;
  height: 51px;
  border: 2px solid #FAFAFA;
  border-radius: 40px;
  font-family: 'JKR Haas Grotesk Bold';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #FAFAFA;
}

.unseal-demo-button{
  cursor:pointer;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 192px;
  height: 51px;
  border-radius: 40px;
  font-family: 'JKR Haas Grotesk Bold';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color:white;
  background: #43BA7D;
}



.secret-safe-with-us{
  font-family: 'JKR Haas Grotesk Bold';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  color: #8D8D8D;
  width:100%;
  margin-bottom:32px;

}

.secret-safe-with-us-subtext{
  height: 19px;
  font-family: 'JKR Haas Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #8D8D8D;
}

.create-center-right-nft-title{
  box-sizing:border-box;
  display: flex;
  align-items:center;
  justify-content:center;
  padding: 0px 20px;
  height: 46px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  font-family: 'JKR Haas Grotesk Bold';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #8D8D8D;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.write-secret-message{
  font-family: 'JKR Haas Grotesk Bold';
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 43px;
  color: #FAFAFA;
  margin-top:32px;
  margin-bottom:32px;
}

.secret-message-box{
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px; 
  width: 400px;
  height: 56px;
  background: #EAFCF3;
  border: 2px solid #43BA7D;
  box-shadow: 0px 0px 70px 4px rgba(67, 186, 125, 0.5);
  border-radius: 10px
}

.secret-message-box-input{
  width:90%;
  font-family: 'JKR Haas Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #757575;
  border:none;
  background: #EAFCF3;
}

.max-chars{
  margin-top:16px;
  font-family: 'JKR Haas Grotesk';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  color: #8D8D8D;
}

.mint-button{
  cursor:pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 40px;
  height: 72px;
  background: #43BA7D;
  border-radius: 40px;
  box-sizing:border-box;
  font-family: 'JKR Haas Grotesk Bold';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #FAFAFA;
  position:absolute;
  bottom:70px;
  right:372px;
  box-sizing:border-box;
}

.mint-button.mint-button-disabled{
  color:#616161;
  border: 1px solid #616161;
  pointer-events:none;
  background:#0A0909;
}

.success-button-row{
  width:100%;
  display:flex;
  align-items:center;
  justify-content:space-between;
  margin-top:32px;
  margin-bottom:16px;
}

.also-view{
  font-family: 'JKR Haas Grotesk';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  color: #8D8D8D;
}
