html, body, #root{
  width:100%;
  height:100%;
  padding:0px;
  margin:0px;
}

@font-face {
  font-family:JKR Haas Grotesk Bold;
  src: url("/public/fonts/JKR-Haas-Grotesk-Knowles.otf") format("opentype");
}

@font-face {
  font-family:JKR Haas Grotesk;
  src: url("/public/fonts/JKR-Haas-Grotesk-Ritchie.otf") format("opentype");
}

.landing-container-center-content .wallet-adapter-button, .landing-container-center-content .wallet-adapter-button-trigger{
  all:unset !important;
}


.header-wallet-button .wallet-adapter-button{
}


.header-wallet-button .wallet-adapter-button-start-icon{
}

.header-page{
  width:100%;
  height:100%;
}

.header-page-black{
  width:100%;
  height:100%;
  background: #0A0909;
}

.header-page-centered{
  display:flex;
  align-items:center;
  justify-content:center;
}

.green {
  color:#43BA7D;
}
