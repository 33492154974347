.nfts-detected-title{
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #0A0909;
  margin: 56px 0px;
}

.holder-nft-container{
  width: 304px;
  height: 452px;
  display:flex;
  flex-direction:column;
  margin: 0px 20px;
  margin-bottom:56px;
}

.holder-nft-image{
  box-sizing:border-box;
  width: 304px;
  height: 304px;
  border: 1px solid #0A0909;
  border-radius: 20px 20px 0px 0px;
}

.holder-nft-bottom{
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  width: 304px;
  height: 149px;
  background: #FAFAFA;
  border: 1px solid #0A0909;
  border-radius: 0px 0px 20px 20px;
}

.holder-nft-sealed-tag{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 16px;
  height: 35px;
  background: rgba(67, 186, 125, 0.2);
  border-radius: 10px;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  color: #43BA7D;
}

.holder-nft-unsealed-tag{
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  color: #FF4949;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 16px;
  gap: 8px;
  height: 35px;
  background: rgba(255, 0, 0, 0.2);
  border-radius: 10px;
}

.holder-nft-title{
  font-family: 'JKR Haas Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  color: #0A0909;
  margin-top:16px;
}

.holder-nfts-list{
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:center;
  width: 100%;
  flex-wrap: wrap;
}

.view-nft-image{
  width: 400px;
  height: 400px;
  border-radius: 24px;
}

.nft-view-title{
  font-family: 'JKR Haas Grotesk Bold';
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 43px;
  color: #0A0909;
  margin: 24px 0px;
}

.nft-view-sealed-tag{
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 120%;
  color: #43BA7D;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 16px;
  width: 104px;
  height: 40px;
  background: rgba(67, 186, 125, 0.2);
  border-radius: 10px;
  box-sizing:border-box;
}

.nft-view-unsealed-tag{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 16px;
  height: 35px;
  background: rgba(255, 0, 0, 0.2);
  border-radius: 10px;
  box-sizing:border-box;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  color: #FF4949;
}

.nft-view-subtext{
  font-family: 'JKR Haas Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #8D8D8D;
  margin-bottom:24px;
}

.nft-view-sealed-blurb-box{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-bottom:32px;
}

.nft-view-sealed-blurb-box-top{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px;
  width: 100%;
  height: 80px;
  border-radius: 10px 10px 0px 0px;
  font-family: 'JKR Haas Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #EEEEEE;
  box-sizing:border-box;
  background: url(https://cdn.hngr.co/tamperproof/zebra3.png), #8E8E8E;

}


.nft-view-sealed-blurb-box-bottom{
  position:relative;
  top:-8px;
  z-index:-1;
  width: 100%;
  height: 38px;
  background: #43BA7D;
  border-radius: 10px;
  display:flex;
  align-items:center;
  justify-content:center;
  font-family: 'JKR Haas Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #FAFAFA;
}

.bottom-blurb-box-inner{
  position:relative;
  top:4px;
}

.nft-view-unseal-button{
  cursor:pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 40px;
  gap: 8px;
  height: 56px;
  background: #D90E0E;
  border-radius: 40px;
  box-sizing:border-box;
  width:100%;
  font-family: 'JKR Haas Grotesk Bold';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #FAFAFA;
}

.scratch-off-container{
  width:80%;
  height:100%;
  display:flex;
  align-items:center;
  justify-content:center;
  position:relative;
}

.scratch-off-img{
  height:66px;
  width:100%;
  position:absolute;
  z-index:2;
}

.scratch-off-secret{
  position:absolute;
  z-index:3;
  font-family: 'JKR Haas Grotesk Bold';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #0A0909;
}

.nft-copy-code-btn{
  cursor:pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 56px;
  border: 2px solid #0A0909;
  border-radius: 40px;
  font-family: 'JKR Haas Grotesk Bold';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #0A0909;
}

.close-modal-button{
  cursor:pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 40px;
  width:100%;
  margin-top:32px;
  height: 56px;
  border-radius: 40px;
  color: #0A0909;
  border: 2px solid #0A0909;
  font-family: 'JKR Haas Grotesk Bold';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  box-sizing:border-box;
  cursor:pointer;
}

.no-nfts{
  diplay:flex;
  align-items:center;
  flex-direction:column;
}

.none-detected{
  font-family: 'JKR Haas Grotesk Bold';
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 43px;
  text-align: center;
  color: #0A0909;
  margin-bottom:32px;
}

.no-nfts-subtext{
  font-family: 'JKR Haas Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #0A0909;
}


.no-nfts-subtext-bold{
  font-family: 'JKR Haas Grotesk Bold';
  text-decoration:underline;
  cursor:pointer;
}

.loading-subtext{
  font-family: 'JKR Haas Grotesk';
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #0A0909;
}
